<script setup lang="ts">
import NuxtLinkFlat from './NuxtLinkFlat.vue';

withDefaults(defineProps<{ flat?: boolean }>(), { flat: false });
const route = useRoute();
const to = computed(() => ({
  path: '/login',
  query: route.path.startsWith('/register') ? route.query : {},
}));
</script>

<template>
  <NuxtLinkFlat v-if="flat" :to="to">
    <slot />
  </NuxtLinkFlat>
  <NuxtLink v-else :to="to">
    <slot />
  </NuxtLink>
</template>

<style lang="scss" module></style>
